import './jquery.mjs'
import './jquery-ui.mjs'
import './eform.mjs'

import * as validate from 'jquery-validation'
globalThis.window.validate = validate;
import Popper from 'popper.js'
import 'bootstrap'
import 'js-cookie'
import Tooltip from 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'
import 'wnumb'
import 'bootstrap-timepicker'
import 'bootstrap-touchspin'

// replacement for Metronic's 'bootstrap-switch', which is not maintained anymore
// last stable (working) version was 3.3.4, but it is not compatible with Bootstrap 4
import 'bootstrap4-toggle'
import 'bootstrap-select' // selectpicker() is standard part of Metronic 5
import 'bootstrap-select/dist/js/i18n/defaults-de_DE.js'
import { Dropzone } from "dropzone";

import 'block-ui'
import 'jquery.repeater'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import cssVars from 'css-vars-ponyfill'
import * as metro from '../assets/metro5/assets/demo/default/base/scripts.bundle.fix.js'
Object.assign(globalThis, metro);
import { AppEvewa4, ev3blockUi } from './evewa4.mjs'
import { localize } from "./localization.mjs"

// Twitter / Typeahead.js: autocomplete / dropdown feature. Present in 'E-Mail verfassen' section, e-mails autocomplete.
import Handlebars from 'handlebars' // Same here.
import('typeahead.js').then(m => {
    setModuleReady('typeahead');
});
import('typeahead.js/dist/bloodhound').then(m => {
    globalThis.Bloodhound = m.default;
    setModuleReady('bloodhound');
});

import 'bootstrap-datepicker' // popup .datepicker()
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js'
import 'inputmask/dist/jquery.inputmask.min.js' // .inputmask() calls on mask fields, ES6 inclusion did not work
import 'jstree' // Website-manager tree of pages / menu items
import Cookies from 'js-cookie'
import 'ion-rangeslider'

// Import base CSS files: for used libraries and for the Metronic5 theme
// NB: custom overrides has to be included after these in its own mjs files.
import 'bootstrap-timepicker/css/bootstrap-timepicker.min.css'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
import 'dropzone/dist/basic.css'
import '../css/select2_metro.css'
import 'ion-rangeslider/css/ion.rangeSlider.min.css'

import '../assets/metro5/assets/vendors/base/vendors.bundle.css'
import '../assets/metro5/assets/demo/default/base/style.bundle.css'
import '../assets/fonts/font.css'
import '../css/evewa4.css'
import '../assets/css/colors.css'

// moved from swal defaults in vendors.bundle
const defaultSwalStyle = Swal.mixin({
    width: 400,
    customClass: {
        confirmButton: "btn m-btn m-btn--wide msgok m-btn--custom",
        cancelButton: "btn btn-secondary m-btn m-btn--custom",
        popup: 'evewa4-default'
    },
    showCloseButton: true,
    heightAuto: false,
    padding: '2.5rem',
    buttonsStyling: false,
    confirmButtonColor: null,
    cancelButtonColor: null,
});

globalThis.Tooltip = Tooltip;
globalThis.PerfectScrollbar = PerfectScrollbar;
globalThis.cssVars = cssVars;
globalThis.AppEvewa4 = AppEvewa4;
globalThis.ev3blockUi = ev3blockUi;
globalThis.localize = localize;
globalThis.swal = defaultSwalStyle;
globalThis.Handlebars = Handlebars;
globalThis.Cookies = Cookies;

import("select2/dist/js/select2.full.js").then(m => {
    m.default('select2')($);
    import('select2/dist/js/i18n/de.js').then(m => {
        $.fn.select2.defaults.set('language', 'select2/i18n/de');
        setModuleReady('select2');
    });
});